/* eslint-disable no-template-curly-in-string */
export default {
  featured: 'Destacados',
  popular: 'Popular',
  couponsAndDeals: 'Cupones y ofertas',
  yourFavorites: 'Sus favoritos',
  coupons: 'Cupones',
  headerCoupons: 'Karma | Cupones',
  lastSaved: 'Últimos guardados',
  favorites: 'Favoritos',
  trending: 'Tendencias',
  weAreLoving: 'Nos encanta',
  karmaHome: 'Karma | Inicio',
  goodMorning: 'Buenos días',
  goodAfternoon: 'Buenas tardes',
  goodEvening: 'Buenas noches',
  addFavoriteStores: 'Agregar tiendas favoritas',
  nothingFound: 'Nada encontrado',
  number: '{{value, number}}',
  clearNumber: '{{value, clearNumber}}',
  saveSettings: 'Guardar ajustes',
  enableBrand: 'Habilitar {{name}}',
  chrome: 'Chrome',
  edge: 'Edge',
  firefox: 'Firefox',
  ie: 'Internet Explorer',
  oldEdge: 'Edge',
  opera: 'Opera',
  safari: 'Safari',
  remove: 'Eliminar',
  sponsored: 'Patrocinado',
  seeAll: 'Ver todo',
  favorite: 'Favorito',
  onSale: 'En oferta',
  lowInStock: 'Poco en stock',
  backInStock: 'Nuevamente en stock',
  coupon: 'Cupón',
  soldOut: 'Agotado',
  fulfilled: 'Completado',
  deal: 'Oferta',
  price: '{{currency}}{{value, number(minimumFractionDigits: 2)}}',
  dayRemaining: '{{count, number}} día restante',
  dayRemaining_plural: '{{count, number}} días restantes',
  karmaStores: 'Tiendas de Karma Cash',
  priceLeftToWithdraw: 'Solo quedan {{currency}}{{value, number}} para retirar sus premios.',
  fullPrice: '{{currency}}{{value, number}}.{{fraction, fraction}}',
  fraction: '.{{value, fraction}}',
  pendingCash: 'Efectivo pendiente',
  payouts: 'Pagos',
  connectWithPayPal: 'Conectar con PayPal',
  selectCause: 'Seleccionar causa',
  dayBetweenPayouts: 'El efectivo puede retirarse {{count, number}} día después de confirmada la compra.',
  dayBetweenPayouts_plural: 'El efectivo puede retirarse {{count, number}} días después de confirmada la compra.',
  list: 'Lista:',
  emptyWallet: 'Su billetera está vacía',
  startShoppingToEarn: '¡Compre para ganar premios!',
  withdraw: 'Retirar',
  yourDonations: 'Sus donaciones',
  outOfAmount: 'De {{currency}}{{value, number}}',
  gettingCloser: '¡Se está acercando!',
  canWithdrawEarnings: 'Ahora puede retirar sus ganancias.',
  workedAt: 'Funcionó {{date}}',
  copied: '¡Copiado!',
  getCoupon: 'Obtener cupón',
  shopNow: 'Comprar ahora',
  karmaLogo: 'Logo de Karma',
  stores: 'Tiendas',
  myLists: 'Mis listas',
  uppercase: '{{text, uppercase}}',
  capitalize: '{{text, capitalize}}',
  all: 'Todo',
  lastWeek: 'Semana pasada',
  lastMonth: 'Mes pasado',
  lastThreeMonths: 'Últimos 3 meses',
  clickID: 'Id. de clic:',
  date: 'Fecha',
  time: 'Hora',
  retailer: 'Minorista',
  orderID: 'Id. de orden',
  startShoppingToEarnKarmaCash: 'Comience a comprar para ganar Karma Cash,',
  browseStores: 'buscar tiendas',
  doNotHaveClicks: 'No tiene clic en este período de tiempo.',
  doNotHaveTransactions: 'No tiene transacciones en este período de tiempo.',
  payPalAlt: 'PayPal',
  payPal: 'PayPal:',
  welcomeBonus: '¡Bonificación de bienvenida!',
  karmaGives: '¡Karma Gives!',
  karmaGivesRefund: '¡Reembolso de Karma Gives!',
  completed: 'Completado',
  notQualified: 'No califica',
  pending: 'Pendiente',
  cancelled: 'Cancelado',
  paid: 'Pagado',
  empty: 'Vacío',
  expired: 'Vencido',
  discoverAndShop: 'Descubrir y comprar',
  contactUs: 'Contáctenos',
  news: 'Novedades',
  faq: 'Preguntas frecuentes',
  aboutUs: 'Sobre nosotros',
  karmaItem: 'Artículo Karma',
  email: 'Correo electrónico',
  congrats: '¡Felicidades!',
  congratulations: '¡Felicitaciones!',
  getKarmaButton: '¡Obtenga el botón Karma!',
  saveThingsToBuy: '¡Guarde cosas que desea comprar después con Karma!',
  savedFirstItem: '¡Guardó su primer producto!',
  getTheButton: 'Obtenga el botón',
  savedFirstItem_plural: '¡Ya guardó algunos artículos!',
  iWantToSeeOther: 'Quiero ver otras <1>tiendas admitidas</1>',
  neverMissProducts: '¡Nunca se pierda cuando un producto esté en oferta o nuevamente en stock!',
  shareByEmail: 'Compartir por email',
  whenIFindSmth: 'Cuando encuentro algo que quiero, ¡lo guardo en Karma!',
  completeSignUp: 'Completar registro',
  addToList: 'Agregar a lista',
  chat: 'Chat',
  karmaCash: 'Karma Cash',
  clickToSaveItem: 'Haga clic para guardar un artículo',
  createListAndPressEnter: "Crear lista y presionar 'Enter'",
  confirmDeleteProduct: '¿Está seguro de que desea eliminar este producto?',
  shareOnFacebook: 'Compartir en Facebook',
  selectAll: 'Seleccionar todo',
  selectProp: 'Seleccionar {{name}}',
  delete: 'Borrar',
  fulfill: 'Completar',
  mute: 'Silenciar',
  anyPriceChange: 'Cualquier cambio de precio',
  priceOff: '{{value, number}} % de descuento',
  notifyWhen: 'Notificar cuando',
  tweetOnTwitter: 'Tuitear en Twitter',
  shareProduct: 'Compartir producto',
  markAsFulfilled: 'Marcar como completo',
  unmuteNotifications: 'Desilenciar notificaciones',
  muteNotifications: 'Silenciar notificaciones',
  priceEvolution: 'Evolución del precio',
  logOut: 'Cerrar sesión',
  twitter: 'Twitter',
  facebook: 'Facebook',
  instagram: 'Instagram',
  whatsapp: 'WhatsApp',
  messenger: 'Messenger',
  privacyPolicy: 'Política de privacidad',
  confirmDelete: '¿Está seguro de que desea eliminar estos productos?',
  termsAndConditions: 'Términos y condiciones',
  logosProvidedBy: 'Logotipos proporcionados por {{name}}',
  copyright:
    'Copyright © {{year, clearNumber}} Karma Shopping Ltd., que opera como Karma. Todos los derechos reservados.',
  noNotifications: 'Sin notificaciones',
  clearAllNotifications: 'Limpiar todas las notificaciones',
  areYouSureRemoveNotifications: '¿Está seguro de que desea eliminar todas las notificaciones?',
  deleteProducts: 'Borrar productos',
  no: 'No',
  yes: 'Sí',
  headerStores: 'Karma | Tiendas',
  clearNotification: 'Limpiar notificación',
  clear: 'Limpiar',
  bell: 'alarma',
  remindInMinute: 'Recordarme en {{value, number}} minuto',
  remindInMinute_plural: 'Recordarme en {{value, number}} minutos',
  remindInHour: 'Recordarme en {{value, number}} hora',
  remindInHour_plural: 'Recordarme en {{value, number}} horas',
  retailers: 'Vendedores',
  claimKarmaCash: 'Reclame hasta {{percent, number}} % de Karma Cash',
  clickOnClaimButton: "Haga clic en el botón ‘Reclamar hasta {{percent, number}} % de Karma Cash'.",
  goShopping: 'Compre',
  rackUpCash: 'Acumule efectivo',
  getPaidDirectly: 'Reciba pagos directos',
  earnRealMoney: 'Gane dinero real en cada compra.',
  trackYourEarnings: 'Controle sus ganancias y retire dinero cada temporada.',
  ordinalNumber: '{{value, fraction}}',
  relatedStores: 'Tiendas relacionadas',
  defaultWhatHappensNext:
    'Para ganar Karma Cash automáticamente en su próxima compra, haga clic en uno de nuestros vendedores Karma Cash elegibles en Karma, donde podrá seguir comprando como de costumbre. Recuerde, solo puede ganar Karma Cash visitando vendedores elegibles en la aplicación o panel de Karma (incluidas notificaciones automáticas), o interactuando con la extensión del navegador de Karma en la computadora/móvil antes de comprar. Cuando el vendedor verifique su compra, Karma confirmará sus ganancias en su billetera Karma.',
  defaultTermsAndConditions:
    'Karma Cash no está disponible para la compra de tarjetas de regalo o en compras que utilizan una tarjeta de regalo, crédito de tienda o puntos de fidelidad u otro programa de puntos. Usar códigos promocionales que no están en la página de Karma puede anular Karma Cash. Puede haber restricciones adicionales para categorías o productos específicos.',
  whatHappensNext: '¿Qué sucede luego?',
  about: 'Acerca de',
  readMore: 'Leer más',
  frequentlyAskedQuestions: 'Preguntas frecuentes',
  loadMore: 'Cargar más',
  questionButton: 'botón de preguntas',
  headerKarmaCash: 'Karma | Premios en efectivo',
  recommended: 'Recomendado',
  highToLow: 'Alto a bajo',
  lowToHigh: 'Bajo a alto',
  sortBy: 'Ordenar por:',
  exploreStores: 'Explorar tiendas',
  weHaveNotFoundStores: 'No encontramos tiendas que coincidan con sus solicitudes',
  weHaveNotFoundCoupons: 'No encontramos cupones que coincidan con sus solicitudes',
  headerApps: 'Karma | Aplicaciones',
  karmaApps: 'Obtenga buen karma, siempre.',
  stayUpdated:
    'Manténgase actualizado en todos sus dispositivos. Nuestro asistente de compras digital le permite guardar artículos de más de {{number, number}} mil tiendas.',
  getFaster: 'Tenga una experiencia más rápida y conveniente al descargar Karma en su Mac.',
  headerProfile: 'Karma | Perfil',
  headerWallet: 'Karma | Billetera',
  totalBalance: 'Saldo total',
  editYourAccount: 'Edite su cuenta',
  emailAddress: 'Dirección de email',
  enterEmailAddress: 'Ingresar dirección de email',
  enterEmail: 'Ingresar email',
  enterEmailLower: 'Ingresar email',
  phoneNumber: 'Número de teléfono',
  enterPhoneNumber: 'Ingresar número de teléfono',
  fullName: 'Nombre completo',
  enterFullName: 'Ingresar nombre completo',
  enterPlaceholder: 'Ingresar {{name}}',
  birthday: 'Cumpleaños',
  year: 'Año',
  month: 'Mes',
  day: 'Día',
  gender: 'Género',
  language: 'Idioma',
  currency: 'Moneda',
  multiCurrency: 'Monedas múltiples',
  male: 'Masculino',
  female: 'Femenino',
  notWantShare: 'No quiero compartir',
  mobilePush: 'Alerta móvil',
  webPush: 'Alerta web',
  disconnectFromPayPal: 'Desconectar de PayPal',
  loginAsUser: 'FUT',
  selectYearAndMonth: 'Seleccione año y mes primero',
  notSureWhereToStart: '¿No sabe dónde comenzar?',
  youDidntSaveItems: 'Aún no ha guardado ningún artículo.',
  searchItems: 'Buscar artículos',
  headerDashboard: 'Karma | Panel',
  headerSales: 'Karma | Ofertas',
  couldNotFindStores: 'Ups, no encontrarmos ninguna oferta',
  karmaGivesValue: '${{value, number}}',
  karmaGivesModalTitle: 'Aquí tiene ${{value, number}} GRATIS para comenzar',
  hereIsABitOfGoodKarma: '¡Aquí encontrará un poco de buen karma por registrarse con nosotros!',
  collectAndStartSaving: 'Cobre y comience a ahorrar',
  termsAndConditionsAppersant: 'Términos y condiciones',
  mayApply: 'puede aplicar',
  termsAndConditionsError: 'Acepte primero nuestros términos y política de privacidad.',
  validEmailError: 'Ingrese un email válido.',
  completeSignUpCapital: 'Completar registro',
  or: 'O',
  password: 'Contraseña',
  joinUs: 'Acompáñenos',
  reviewsPlus: 'Más de {{amount, number}} mil reseñas',
  logIn: 'Ingresar',
  termsAgreement: 'Al registrarse, acepta nuestros <1>Términos</1> y <3>Política de privacidad</3>',
  termsAgreementWithCheckbox: 'Acepto los <1>Términos y condiciones</1> y <3>Política de privacidad</3>',
  createANewList: 'Crear nueva lista',
  listCreateBlickError: 'El nombre de lista no puede estar vacío',
  enterListName: 'Ingresar nombre de lista',
  listDescriptionOptional: 'Descripción de lista (Opcional)',
  listName: 'Nombre de lista',
  enterDescriptionForList: 'Ingresar descripción para su lista',
  save: 'Guardar',
  deleteList: 'Borrar lista',
  areYouSureRemoveList: '¿Está seguro de que desea eliminar esta lista?',
  editList: 'Editar lista',
  listNameCanNotBeBlanck: 'El nombre de lista no puede estar vacío',
  shareList: 'Compartir lista',
  notAMemberCreateAccount: '¿No es miembro? <1>¡Cree una cuenta!</1>',
  backToLogin: 'Regresar para ingresar',
  enterYourEmail: 'Ingresar dirección de email',
  resetPassword: 'Restablecer contraseña',
  forgotPasswordMessage:
    'Recibirá un correo electrónico con instrucciones sobre cómo restablecer su contraseña en unos minutos.',
  forgotPassword: '¿Olvidó su contraseña?',
  reCaptchaMessage:
    'Este sitio está protegido por reCAPTCHA y se aplican la <1>Política de privacidad</1> y <3>Términos de servicio</3> de Google',
  pleaseScan: 'Escanear',
  saveAnItem: 'Guarde un artículo',
  areYouSureFulfilled: '¿Está seguro de que desea marcar estos productos como completados?',
  muteTagDesc: '¿Está seguro de que desea silenciar las notificaciones para estos productos?',
  muteTagTitle: 'Silenciar notificaciones',
  ok: 'ACEPTAR',
  canAccessEarningsInPayPal: 'Acceda a sus ganancias en su cuenta de PayPal',
  ccpaTitle:
    'Ley de Privacidad del Consumidor de California (CCPA), enmendada por la Ley de Derechos de Privacidad de California (CPRA)',
  ccpaText: ' Al enviar este formulario, ejerzo mi derecho de cancelar cualquier venta de mi información personal.',
  submit: 'Enviar',
  joinKarmaAndGetRewards: 'Únase a Karma y obtenga {{stake, number}} premios en {{name}}',
  doNotMissOut: 'No se lo pierda. ¡Tal vez pueda ahorrar dinero en su artículo!',
  signUpAndGetMoney: 'Regístrese y obtenga ${{value, number}}',
  itemAlert: '¡Tiene una alerta de artículo!',
  accessKarmaCash: 'Acceda a su Karma Cash',
  youEarnedKarmaCash: '¡Acaba de ganar Karma Cash!',
  verifyYourPaypalAccount: 'Verificar su cuenta<1/>Paypal',
  weHaveSentAnEmailTo: 'Enviamos un email a',
  installed: 'Instalado',
  'Fall Favorites': 'Favoritos otoño',
  'Karma Cash': 'Karma Cash',
  'Clothing & Luxury': 'Indumentaria y Lujo',
  'Home Decor & Furniture': 'Deco hogar y Muebles',
  'Electronics & Office Supplies': 'Electrónica y Oficina',
  Luxury: 'Lujo',
  Clothing: 'Indumentaria ',
  'Handbags & Shoes': 'Bolsos y Calzado',
  'Jewelry & Watches': 'Joyería y Relojes',
  Accessories: 'Accesorios',
  'Beauty Essentials': 'Belleza',
  'Health & Wellness': 'Salud y Bienestar',
  Lifestyle: 'Vida',
  'Sports & Outdoors': 'Deportes y Exteriores',
  Travel: 'Viajes',
  'Electronics & Office supplies': 'Electrónica y Oficina',
  'Entertainment & Online Services': 'Entretenimiento y Servicios online',
  Education: 'Educación',
  'Finance & FinTech': 'Finanzas y FinTech',
  'Pet Supplies': 'Mascotas',
  'Food & Beverages': 'Alimentos y Bebidas',
  Men: 'Hombres',
  Women: 'Mujeres',
  Unisex: 'Unisex',
  Kids: 'Niños',
  Baby: 'Bebés',
  notifications: 'Notificaciones',
  changesSaved: '¡Cambios guardados!',
  headerRetailer: 'Karma | Vendedor',
  headerRedirect: 'Karma | Redireccionando...',
  karma: 'Karma',
  goBackToKarma: 'Regresar a Karma',
  authentication: 'Autenticación',
  authSuccess: '¡Felicidades! Inició sesión con éxito.',
  authFailure: 'Ups, no pudimos autorizarlo. Inténtelo nuevamente luego.',
  mayNotQualify: 'Algunos productos no califican para Karma Cash',
  checkOutRetailers: 'Consulte nuestros vendedores admitidos de Karma Cash.',
  headerHowItWorks: 'Karma | Cómo funciona',
  accept: 'Aceptar',
  headerError: 'Karma | Error',
  pageNotFound: 'Página no encontrada',
  pageDoesNotExist: 'Ups, parece que esta página no existe.',
  backHome: 'Regresar a Inicio',
  startShopping: 'Comience a comprar',
  illustration: 'Ilustración',
  dashboard: 'Panel',
  allRightsReserved: 'Todos los derechos reservados.',
  product: 'Producto',
  disclaimer:
    'Karma Shopping Ltd. opera como Karma. Karma no tiene ni pretende tener titularidad de marcas comerciales de terceros. Las marcas de terceros visibles o accesibles, directa o indirectamente, a través de la plataforma de Karma no necesariamente están relacionadas con Karma o terceros asociados con Karma o proveedores de servicio de Karma.',
  help: 'Ayuda',
  team: 'Equipo',
  howItWorks: 'Cómo funciona',
  supportedStores: 'Tiendas admitidas',
  company: 'Compañía',
  planYourPurchase: '¡Planifique su próxima compra con nuestra aplicación gratuita!',
  itSitsInBrowser: 'Permanece en su navegador web mientras explora todas sus tiendas favoritas.',
  seeSmthYouLike:
    '¿Ve algo que le gusta? Haga clic en el botón para guardarlo en su lista y Karma seguirá el precio y disponibilidad por usted.',
  youWillGetNotified:
    'Se le notificará instantáneamente cuando cualquiera de sus artículos guardados esté en oferta, rebajado o nuevamente en stock en su talle y color preferidos.',
  everythingYouWant:
    'Todo lo que quiera comprar estará en un lugar conveniente, para mantenerlo organizado y que no haga malabares con las listas.',
  headerContactUs: 'Karma | Contáctenos',
  needToGetInTouch: '¿Necesita contactarnos?',
  dropALine: 'Escríbanos',
  comeHangOut: 'Visítenos',
  joinOurFacebookCommunity: 'Únase a nuestra Comunidad Facebook',
  followOnInstagram: 'Síganos en Instagram',
  headerAboutUs: 'Karma | Sobre nosotros',
  addTheItems:
    'Agregue los artículos que quiere comprar, personalice el talle y el color, y la aplicación lo notificará cuando estén en oferta.',
  jonathanSay:
    'Jonathan: Los vendedores aprovecharon la IA durante años, pero hoy el consumidor capitaliza el aprendizaje de máquinas y las herramientas de automatización para lograr eficiencia.',
  justDownload:
    'Descargue un \'botón\' y haga clic en él cuando esté en un artículo que le gusta para guardarlo en su lista. En el móvil, puede "compartir" artículos directamente en las mismas listas.',
  ifYouFind:
    'Si encuentra un artículo que le gusta en línea que excede su presupuesto, configure una alerta de oferta para que se le notifique cuando el precio baje.',
  onlyAHandful: 'Pocas compañías que ofrecen alertas de oferta para miembros en línea siguen en pie como Karma.',
  alwaysBuy: 'Siempre compre en oferta con Karma.',
  headerCareers: 'Karma | Empleo',
  getInTouch: 'Contáctenos:',
  shop: 'Comprar',
  weCouldNotFindResults: "No encontramos resultados para '{{name}}'",
  couldNotFindResults: 'No encontramos ningún resultado',
  shopAllStores: 'Comprar en las tiendas admitidas',
  popularStores: 'Tiendas populares',
  shops: 'Tiendas',
  headerDiscoverAndShop: 'Karma | Descubra y compre los últimos artículos guardados',
  prev: 'Anterior',
  next: 'Siguiente',
  headerDemo: 'Karma | Demostración',
  getTheKarmaButton: 'Obtener el botón Karma',
  installedKarma: '¡Ha instalado Karma correctamente!',
  settingUpAccount: 'Ahora, empecemos por configurar su cuenta.',
  signUpNow: 'Regístrese ahora',
  logInNow: 'Ingrese ahora',
  signUpLater: 'Regístrese luego',
  wePartnerWith: '¡Trabajamos con más de {{number, number}} mil tiendas en línea!',
  pickStoresToSee: 'Elija una de las siguientes tiendas para ver cómo funciona:',
  leaveKarma: 'Considere que saldrá de Karma e irá a la página de la tienda para disfrutar de toda la experiencia.',
  searchFavoriteStores: 'Busque sus tiendas favoritas',
  clickBelowToStart: 'Clic a continuación para comenzar',
  chooseOneOfTheOptions: 'Elija una opción a continuación',
  clickToBegin: 'Clicar la barra de búsqueda para comenzar',
  preferNotToSay: 'Prefiero no decirlo',
  done: 'Hecho',
  headerUnsubscribe: 'Karma | Cancelar suscripción',
  changeMind: '¿Cambió de idea?',
  resubscribeList: 'Vuelva a suscribirse a la lista de correo.',
  resubscribeList_plural: 'Vuelva a suscribirse a su lista de correo aquí.',
  changePreferencesAtAnyTime:
    'También puede cambiar sus preferencias en cualquier momento en <1>ajustes de cuenta.</1>',
  preferencesUpdated: 'Actualizamos sus preferencias de email.',
  sorryToSeeYouGo: '¡Lamentamos que se vaya!',
  reSubscribed: 'Ha sido suscrito nuevamente',
  allowHours: 'Espere hasta {{hours, number}} horas para que los cambios surtan efecto.',
  missWhenYouGone:
    '¡Lo extrañaremos cuando se vaya! Espere hasta {{hours, number}} horas para que los cambios surtan efecto.',
  headerDisablingSubscribe: 'Karma | Deshabilitar suscripción',
  noLongerAlerts: 'Ya no recibirá alertas sobre este artículo.',
  turnAlertsBack: 'Volver a activar alertas.',
  moveAnimations: 'Mover animación del botón',
  headerExtensionDeleted: 'Karma | Extensión borrada',
  areSorryToSeeYouGo: '¡Lamentamos que se vaya!',
  youHaveGotInYourWallet: 'Tiene ${{value}} en su billetera.',
  toWithdraw: ' para retirar sus ganancias!',
  toContinue: ' para continuar obteniendo ganancias!',
  reasonUninstalled: 'Indíquenos por qué ha desinstalado:',
  getInTouchBtn: 'Póngase en contacto',
  howWeCanIncrease: 'Aumente la conciencia e impulse las ventas al publicitar con Karma',
  helpingShoppers:
    'Ayudar a los compradores a descubrir las marcas que les encantarán en los próximos años es una prioridad como asistente personal de compras.',
  whyWeOfferBrands:
    'Por eso ofrecemos marcas que proporcionan constantemente alta satisfacción del cliente para conectarse con nuestro público. Usted alcanza sus metas de campaña y nuestro público conoce su nueva marca favorita.',
  winWin:
    'Ofrecemos marcas que proporcionan constantemente alta satisfacción del cliente para conectarse con nuestro público. Usted alcanza sus metas de campaña y nuestro público conoce su nueva marca favorita; ¡todos ganan!',
  getFeaturedRight: 'Aparezca dentro de nuestra aplicación y panel de extensión del navegador',
  getFeaturedDashboard: 'Aparezca en el panel de Karma',
  putYourHand:
    'Coloque sus esfuerzos, productos o promociones en el lugar que nuestros usuarios visitan cuando están listos para comprar. Podemos crear variedad de contenidos personalizados de marca que impulsarán un buen tráfico donde sea que lo necesite.',
  exactPlace: 'Coloque su marca en el lugar que nuestros usuarios visitan cuando están listos para comprar.',
  dedicatedInAppPlacement: 'Colocación exclusiva en la aplicación',
  dashboardFeaturedBanners: 'Anuncios destacados en el panel',
  dashboardPopUpMessages: 'Mensajes emergentes en el panel',
  customEmailsAndNewsLetters: 'Emails y colocación de boletines personalizados para más de {{subscriptores, number}}',
  customEmails: 'Emails personalizados para más de {{subscribers, number}}',
  getMoreEyes:
    'Obtenga más atención en su última oferta, código de cupón o línea de productos con un email o boletín exclusivo para compradores.',
  putYourBrand: 'Coloque su marca en el lugar que nuestros usuarios visitan cuando están listos para comprar.',
  newsletterInclusion: 'Inclusión en el boletín',
  dedicatedCustomEmails: '{{percent, number}} % de emails personalizados y exclusivos',
  bannerPlacementInEmails: 'Anuncios en emails de notificaciones',
  pushNotificationsToShoppers: 'Notificaciones automáticas para más de {{shoppers, number}}',
  pushNotificationsGiveTouchPoint:
    'Las notificaciones automáticas ofrecen a su campaña otro punto de contacto y altas tasas de aperturas y clics.',
  featureBrandClients: 'Clientes de marcas destacadas',
  growingPerMonth: 'Crecimiento de más de {{number, number}} por mes',
  members: 'Miembros',
  percent: '{{value, number}} %',
  kindOfReachYouExpect: '¿Qué tipo de alcance se puede esperar?',
  ourAudience: 'Nuestro público',
  tabIntoNewSource: 'Utilice una nueva fuente de clientes que califican para su próxima campaña',
  tellUsAboutYourCompany:
    'Cuéntenos un poco sobre su compañía y objetivos, y le mostraremos cómo podemos ayudarlo a superarlos.',
  openAndTap: "Abra Safari y vaya a la página del producto que desea guardar y toque el botón 'Compartir'.",
  shareIllustration: 'Compartir ilustración',
  tapMore: "Toque el botón 'Más' a la derecha del panel.",
  moreIllustration: 'Más ilustración',
  enableIllustration: 'Habilitar ilustración',
  tapEdit: "Toque el botón 'Editar' en la parte superior del panel.",
  enableApp: "Active Karma, toque el botón verde 'agregar' para añadirlo a sus Favoritos y luego toque 'Hecho'.",
  goBackToTheProduct:
    "Vuelva a la página del producto, toque nuevamente el botón 'Compartir' y luego el botón 'Karma' para guardar el producto.",
  recommendedBy: 'Recomendado por',
  ourMembersSavingMoney: 'Nuestros miembros ❤️ ahorrar dinero con Karma. Conozca la razón.',
  addToBrowser: 'Agregar a {{browser}} - Es gratis',
  planPurchases: 'Planifique próximas compras',
  karmaMakesItEasy: 'Karma hace que sea fácil organizar sus próximas compras desde un lugar.',
  buyAtRightTime: 'Compre en el momento adecuado',
  automaticallyAlert: 'Karma alerta automáticamente cuando es el momento adecuado para comprar.',
  cashOnVacation: 'Karma Cash en sus próximas vacaciones',
  karmaPartners: 'Karma trabaja con varios sitios de viajes y hotelería.',
  headerRewards: 'Karma | Los mejores cupones y premios en efectivo',
  mission: 'Nuestra misión',
  empowerYou: 'Empoderarlo para tomar decisiones correctas en el momento adecuado cuando compra en línea.',
  weAreSeriousAboutPrivacy: 'Nos tomamos en serio su privacidad y seguridad',
  weDoNotSellData: 'No vendemos sus datos',
  karmaIsSerious: 'Karma se toma muy en serio sus datos y nunca los venderemos a terceros. Nunca.',
  karmaIsSecure: 'Karma es seguro',
  weUseBankSecurity:
    'Utilizamos seguridad de nivel banco (cifrado de bits de {{bit, number}}) para garantizar que su información esté segura.',
  helpsSaveMore: '¡Karma lo ayuda a ahorrar más!',
  alertMe: 'Recibir alertas',
  buyWithKarmaEarn: 'Compre con Karma - Gane ${{number, number}}',
  productSaved:
    '¡Felicidades! Este producto se guardó en su lista de artículos. Lo notificaremos cuando el precio baje.',
  willApplyTheBestCoupons: '¡Karma aplicará automáticamente los mejores cupones a su compra!',
  downloadAndAutoApply: 'Descargar Karma y aplicación automática',
  whatIsKarma: '¿Qué es Karma?',
  claimUpToRewards: 'Solicite hasta {{number, number}} % en premios en efectivo',
  youMightAlsoLike: 'Quizás también le guste',
  weAreSorry: 'Lo sentimos, algo falló y no se puede mostrar la página.',
  tryToReloadPage: 'Intente volver a cargar la página.',
  Color: 'Color',
  Size: 'Talle',
  latestCoupons: '{{name}} | Últimos cupones y reembolsos de Karma {{year, clearNumber}}',
  neverPayFullPrice: 'Nunca pague el precio completo. Obtenga los cupones y reembolsos de {{name}} de Karma',
  neverPayFullPriceForTag: 'Nunca pague el precio completo de {{name}}',
  saveMoneyWhenShoppingAt: 'Ahorre dinero cuando compra en {{name}}. Únase a Karma gratis',
  saveMoneyWhenShoppingFor: 'Ahorre dinero cuando compra {{name}}. Únase a Karma gratis',
  tagByBrand: 'Karma | {{tag}} por {{brand}}',
  typeToSearch: 'Escriba para buscar',
  getUSDWhenJoinToday: '¡Obtenga ${{value, number}} si se une a Karma hoy!',
  buyProduct: 'Comprar producto',
  userWishList: 'Karma | Lista de {{name}}',
  byWhom: 'por',
  copiedToClipboard: '¡Copiado al portapapeles!',
  send: 'Enviar',
  cause: 'Seleccionar causa:',
  aCause: 'Seleccione una causa:',
  close: 'Cerrar',
  selectThisCause: 'Seleccionar esta causa',
  habitatForHumanity:
    '{{name}} se dedica a aliviar la pobreza de larga duración mediante viviendas sociales asequibles y su misión es crear un entorno seguro en el que las familias y las comunidades puedan prosperar',
  casaDescription:
    'La asociación nacional CASA / GAL apoya y promueve la defensa voluntaria designada judicialmente para que todos los niños que han sufrido maltrato o abandono puedan estar seguros, tener un hogar permanente y la oportunidad de prosperar.',
  endHomelessnessDescription:
    'La Alianza Nacional para {{name}} es una organización no partidista dedicada a prevenir y acabar con la falta de vivienda en Estados Unidos',
  pathDescription: 'PATH lucha por acabar con la falta de vivienda de personas, familias y comunidades',
  feedingAmericaDescription: 'Feeding America es la mayor organización nacional de lucha contra el hambre',
  mealsOnWheelsDescription:
    '{{name}} ayuda a los programas comunitarios locales a mejorar la salud y la calidad de vida de los mayores a los que atienden para que no pasen hambre ni estén aislados',
  actionAgainstHunger:
    '{{name}} es una organización humanitaria mundial que actúa con decisión contra las causas y los efectos del hambre',
  supportMedicalAdvancement: 'Apoyar avances médicos',
  saveChildsHeartDescription:
    "Save A Child's Heart sana los corazones sin importar la raza, la religión, el sexo, la nacionalidad o la situación económica",
  su2c: 'Stand Up To Cancer (SU2C) financia y desarrolla los tratamientos contra el cáncer más novedosos y prometedores para ayudar a los pacientes en la actualidad',
  drWithoutBorders: '{{name}} es un movimiento mundial independiente que brinda ayuda médica donde más se necesita',
  activeMinds:
    '{{name}} es la principal organización sin fines de lucro del país que apoya la sensibilización y la educación sobre la salud mental de los adultos jóvenes',
  cleanAirTaskForceDesc:
    '{{name}} impulsa el cambio de tecnologías y políticas que se necesita para tener un planeta con cero emisiones y alto nivel energético a un costo asequible',
  wwfDesc:
    'La misión de {{name}} es conservar la naturaleza y reducir las amenazas más graves para la diversidad de la vida en la Tierra',
  waterkeepsDesc:
    '{{name}} aboga por el agua limpia. Waterkeeper Alliance trabaja para garantizar que todas las comunidades del mundo tengan agua potable, apta para pescar y nadar',
  xprice:
    'XPrize trabaja a diario para crear un mundo mejor en el que todo el mundo tenga acceso a agua limpia, alimentos nutritivos, viviendas asequibles, aprendizaje eficaz, atención médica de primer nivel y energía abundante y no contaminante',
  globalActDesc: '{{name}} trabaja para acabar con la explotación sexual y comercial',
  pcaa: '{{name}} trabaja intensamente para hacer realidad nuestra visión de un mundo en el que todos los niños crezcan felices, sanos y preparados para triunfar en familias y comunidades solidarias',
  endCAN:
    'EndCAN sensibiliza sobre los efectos del maltrato en la salud, la salud mental y la salud pública, con la misión de erradicar el maltrato y abandono infantil',
  futuresDesc:
    'FUTURES ofrece programas, políticas y campañas innovadoras que capacitan a las personas y organizaciones que trabajan para erradicar la violencia contra las mujeres y los niños en todo el mundo',
  thornDesc: '{{name} crea tecnología para defender a los niños frente a los abusos sexuales.',
  'Eradicate Homelessness': 'Erradicar la falta de vivienda',
  'Help End Hunger': 'Ayudar a erradicar el hambre',
  'Support Medical Advancement': 'Apoyar avances médicos',
  'Combat Climate Change': 'Combatir el cambio climático',
  'Act To End Abuse': 'Actuar para erradicar el maltrato',
  itemsYouSaved: 'Artículos que guardaste por menos',
  beta: 'BETA',
  alsoAvailableAt: 'También disponible en',
  atName: 'en {{name}}',
  bestPrice: 'Mejor precio',
  passwordWasSuccessfullyReset: 'La contraseña se restableció correctamente',
  paidWith: 'Pagado con',
  infoFirstNumber: '+{{number, number}}{{unit}}',
  infoFirstTitle: 'Miembros',
  infoSecondNumber: '+{{number, number}}{{unit}}',
  infoSecondTitle: 'Visitas mensuales',
  infoThirdNumber: '+{{number, number}}{{unit}}',
  infoThirdTitle: 'Listas de correo electrónico',
  infoFourthNumber: '{{value, number}}%',
  infoFourthTitle: 'Millenials',
  infoFifthNumber: '{{value, number}}%',
  infoFifthTitle: 'Mujeres',
  infoSixthNumber: '{{value, number}}%',
  infoSixthTitle: 'Está en EE. UU.',
  infoSeventhNumber: '+{{number, number}}{{unit}}',
  infoSeventhTitle: 'Notificaciones automáticas móviles voluntarias',
  infoEighthNumber: '+{{number, number}}{{unit}}',
  infoNinthPreNumber: 'Los usuarios gastarán más de',
  infoNinthNumber: '${{number, number}}{{unit}}',
  infoNinthTitle: 'A finales de {{year, clearNumber}}',
  karmaIsAlwaysWithYou: 'Karma siempre te acompaña cuando compras',
  saveItemsToBuy: 'Guarda artículos y te diremos cuándo comprar',
  weDoTheWork: 'Nos esforzamos por encontrar los mejores cupones',
  earnRealCashThatAdds: 'Gana dinero real que se acumula en tu monedero',
  setUpStrongPassword: 'Establece una contraseña segura',
  settingEmailAccount:
    '¿El último paso para ahorrar dinero con Karma? Regístrate para que podamos enviarte alertas importantes sobre tus artículos, como rebajas de precios.',
  settingPasswordAccount:
    'La contraseña debe contener un mínimo de {{number, number}} caracteres y no debe incluir tus credenciales de correo electrónico.',
  countWaysToUse: '{{number, number}} Formas de usar Karma',
  goBack: 'Atrás',
  pleaseChooseStrongerPassword: 'Elija una contraseña más fuerte',
  pleaseEnterValidEmailAddress: 'Ingrese un email válido',
  illSignUpLater: 'Me registraré más tarde',
  enterYourEmailAddress: 'Ingrese su email y le enviaremos un enlace para restablecer su contraseña.',
  headerOnboardingIosExtension: 'Karma | Extensión IOS Integrada',
  weWillAutomaticallyApplyCouponsWhereverYouShop: 'Aplicaremos cupones automáticamente donde sea que compre',
  continue: 'Continuar',
  changeLanguage: 'Cambiar idioma',
  selectedLanguage: 'Seleccionar idioma',
  headerMuteItem: 'Karma | Inactivar artículo',
  itemHasBeenMuted: '¡Listo! Este artículo ha sido inactivado.',
  toUnmuteItem: 'Para reactivar este artículo:',
  headToMyItems: 'Ve a la pestaña ‘Mis artículos’',
  clickThreeButtonsInItem: 'Pulsa los tres botones de la esquina inferior derecha del artículo',
  clickUnmute: 'Pulsa ‘Rectivar’',
  weveJustRaisedInFunding:
    '¡Hemos recaudado ${{value, number}} millones en financiación de la serie A para ayudarte a comprar con inteligencia!',
  weveJustRaised: '¡Hemos recaudado ${{value, number}} M',
  landingReview1: '¡Excelente aplicación!¡Recibí una alerta de rebaja y me ahorré mucho dinero!',
  landingReview2: 'Me ahorré ${{value, number}} en mi primera compra. ¡Muy impresionante!',
  landingReview3: 'Es una aplicación útil; me gustan las notificaciones y las recompensas.',
  landingReview4: 'Aún no he tenido una experiencia de compra mejor que esta.',
  landingReview5: '¡Me ahorré un {{value, number}}%! ¡No me habría enterado de la oferta de otro modo!',
  landingReview6: 'Facilita el rastreo de los productos que te gustan... ¡La recomiendo...!',
  landingReviewAuthor1: 'Justin Nguyen',
  landingReviewAuthor2: 'Amy Ellis',
  landingReviewAuthor3: 'Maude Aethelredd',
  landingReviewAuthor4: 'Patrick Slocum',
  landingReviewAuthor5: 'Michelle Findley',
  landingReviewAuthor6: 'Varsha Hulekal',
  auxiliaryDate: '{{date}}',
  resetMyPassword: 'Restablecer mi contraseña',
  repeatPassword: 'Repetir contraseña',
  continueAsGuest: 'Continuar como invitado',
  viewAt: 'Ver en {{- name}}',
  signUp: 'Registrarse',
  youMayAlsoLike: 'También te puede gustar',
  buyOnShop: 'Comprar',
  viewOn: 'Ver en {{- name}}',
  quickView: 'Vista rápida',
  newList: 'Lista nueva',
  undo: 'Deshacer',
  productDeleted: 'Producto eliminado',
  linkCopiedToClipboard: 'enlace copiado al portapapeles',
  wishlists: 'Lista de deseos',
  headerPartnerships: 'Karma | Asociaciones',
  letsJoinForces: 'Unamos fuerzas',
  bePartOfTheSmartShoppingRevolution:
    'Forma parte de la revolución de las compras inteligentes. Haremos tu marca visible ante {{number, number}} millones de compradores que están listos para comprar.',
  seeKarmaInAction: 'Observa a Karma en acción',
  discoverHowKarma:
    'Descubre cómo las soluciones basadas en la automatización y la inteligencia artificial de Karma mejoran todo el proceso de compra, llevando tráfico de interés hacia tus ofertas.',
  stepUpYourSales: 'Aumenta tus ventas',
  averageROAS: 'Retorno de la inversión<br/>en anuncios promedio',
  increaseInAOV: 'Aumento en el valor<br/>promedio de los pedidos',
  useKarmaToPlanTheirNextPurchase: 'Usa Karma para planificar<br/>tu próxima compra',
  meetOurShoppers: 'Conoce a nuestros compradores',
  meetOurShoppersDescription:
    'Aprovecha nuestras soluciones de marketing basadas en el rendimiento y deja tu huella en los compradores con gran intención de compra. Las colocaciones en nuestra multiplataforma te permiten llegar a los usuarios dondequiera que estén.',
  audienceInfoFirstNumber: 'Más de {{number, number}}{{unit}}',
  audienceInfoFirstTitle: 'Usuarios',
  audienceInfoSecondNumber: 'Más de {{number, number}}{{unit}}',
  audienceInfoSecondTitle: 'Visitas mensuales',
  audienceInfoThirdNumber: 'Más de {{number, number}}{{unit}}',
  audienceInfoThirdTitle: 'Artículos guardados',
  audienceInfoFourthNumber: '{{value, number}} %',
  audienceInfoFourthTitle: 'Usuarios en EE. UU.',
  audienceInfoFifthNumber: '{{value, number}} %',
  audienceInfoFifthTitle: 'Generación Z y millenials',
  audienceInfoSixthNumber: '{{value, number}} %',
  audienceInfoSixthTitle: 'Mujeres compradoras',
  ourUsersLoveUs: '¡Nuestros usuarios nos aman!',
  partnershipsReview1:
    '¡Una gran extensión! Lo he usado por años y es genial poder guardar tantos productos en un solo lugar. ¡Hace que las compras en línea sean mucho menos estresantes!',
  partnershipsReviewAuthor1: 'Millie Robinson',
  partnershipsReview2:
    'Viniendo de una persona que hace MILES de compras en línea, puedo decir honestamente que Karma es el mejor amigo de los compradores por muchas razones...',
  partnershipsReviewAuthor2: 'Sadie Mae',
  partnershipsReview3:
    'La mejor aplicación de compras en Play Store. Es muy fácil de usar. ¡Tengo todos mis artículos guardados en mis tiendas favoritas en una sola aplicación! 👍🏻',
  partnershipsReviewAuthor3: 'Kimberly Rogers',
  performanceBasis: 'Base de rendimiento',
  fixedFee: 'Tarifa fija',
  thanksForSubmitting: '¡Gracias por enviar!',
  someoneFromOurPartnershipsTeamWillBeInTouchShortly:
    'Alguien de nuestro equipo de asociaciones se comunicará contigo en breve.',
  letsKickStartOurPartnership: '¡Comencemos con nuestra asociación!',
  emailOrPhoneNumber: 'Correo electrónico o número de teléfono',
  retailerName: 'Nombre de minorista',
  affiliateNetwork: 'Red de afiliados',
  budgetStructure: 'Estructura del presupuesto',
  budget: 'Presupuesto',
  tellUsMore: 'Cuéntanos más',
  pleaseFillInAllRequiredFields: 'Completa todos los campos obligatorios',
  partnerships: 'Asociaciones',
  weCannotSaveThisItem: 'No se puede guardar este artículo <1/>, esta tienda no se admite',
  theresCoupon: '¡Hay un cupón!',
  yourItemIsOffWithCode: 'Tu artículo está {{value, number}} % OFF con el código {{code}} en {{- name}}.',
  muteAllNotification: 'Silenciar notificaciones',
  unMuteAllNotification: 'Activar notificaciones',
  notificationSettings: 'Configurar notificaciones',
  deleteNotification: 'Eliminar notificación',
  notificationDeleted: 'Notificación eliminada',
  itemMuted: 'Artículo silenciado',
  itemUnMuted: 'Artículo activado',
  priceDrop: 'Precio rebajado',
  superDeal: '🔥 Super oferta',
  notSureWhenToBuy: '¿No sabes qué comprar? Crea una cuenta gratis y te informaremos las ofertas.',
  tcMayApply: 'Es posible que se apliquen términos y condiciones',
  earnKarmaCash: 'Gana hasta un {{percent, number}}% de Karma Cash.',
  saveToKarma: 'Guardar articulo en Karma',
  getKarmaToTrackItem: 'Obtener Karma para rastrear el artículo',
  itemSaved: '¡Artículo guardado!',
  youCanNowFindItem: 'Ahora puedes encontrar tu artículo en tu panel de Karma.',
  getKarmaExtension: 'Es imprescindible obtener la extensión<1/> Karma para rastrearlo ',
  notifyYou: '¡Te avisaremos cuando sea el mejor momento para comprar!',
  downloadTheExtension: 'Descargar la extensión',
  outOfStock: 'Agotado',
  invalidEmailError: 'Correo electrónico no válido',
  collapse: 'Colapso',
  expand: 'Expandir',
  lists: 'Listas',
  organizeYourItemsIntoWishListsWithJustAClick: 'Organiza tus artículos en las listas de deseos con solo un clic.',
  newItem: 'Nuevo Articulo',
  helpCenter: 'Centro de ayuda',
  priceHighToLow: 'Precio de mayor a menor',
  priceLowToHigh: 'Precio de menor a mayor',
  sale: 'Rebajas',
  filterBy: 'Filtrar por:',
  karmaExclusive: 'Exclusivo de K',
  noItemsFound: 'No se encontraron artículos',
  select: 'Selecciona una opción',
  likeSneakersOrBlackFriday: 'Como ‘Sneakers’ o ‘Black Friday’',
  updateList: 'Actualizar lista',
  firstName: 'Nombre de pila',
  lastName: 'Apellido',
  enterFirstName: 'Ingresa el nombre',
  enterLastName: 'Ingresa el apellido',
  recentSearches: 'Búsquedas recientes',
  addYourFavoriteStores: 'Agrega tus tiendas favoritas',
  toAccessTheirLatestCoupons: 'para acceder a tus últimos cupones',
  logosProvidedByClearbit: 'Logotipos proporcionados por Clearbit',
  dateOfBirth: 'Fecha de nacimiento',
  weHaveEmailedYouALinkToResetYourPassword:
    'Te enviamos un correo electrónico con un enlace para restablecer tu contraseña.',
  didNotGetIt: '¿No lo entendiste?',
  profile: 'Perfil',
  promotionsAndActivity: 'Promociones y actividad',
  couponsForFavoriteStores: 'Cupones para tiendas favoritas',
  accountActivity: 'Actividad de la cuenta',
  powerShopper: '¿Comprador de energía? 💪',
  enterLinkToAnyItemPage: 'Ingresa un enlace a cualquier página de artículo',
  saveStuffWithZeroFuss: '<0>Obtén la extensión del navegador </0> y guarda las cosas sin problemas.',
  viewMore: 'Ver más información',
  tooHotToMiss: 'Demasiado caliente para perderse',

  // WebsiteLandingKarmaOld
  joinShoppers: 'Únase a {{number, number}} millones de compradores',
  waysToUse: 'Formas de usar Karma',
  youCanUseKarma:
    'Puede utilizar Karma de muchas maneras, como cerciorarse de conseguir el mejor precio en el momento correcto y planear sus próximas compras. Estos son algunos ejemplos que recomendamos:',
  livesInBrowser: 'Karma vive en su navegador',
  browserExtension: 'La extensión de navegador de Karma lo encuentra siempre que compre en la web.',
  helpsYouPlan: 'Lo ayuda a planificar sus próximas compras',
  notReadyToBuyJust:
    '¿No está listo para comprar? Guárdelo para más tarde con la extensión de navegador de Karma y compre más cuidadosamente. Controle todos los artículos guardados en un solo lugar. ',
  automaticallyFindsCouponCodes: 'Encuentra automáticamente códigos de cupón',
  findsAndAppliesCodes:
    'Karma encuentra y aplica automáticamente el mejor cupón para usted, de modo que sepa que siempre obtendrá el mejor valor.',
  soYouCanBuyAtTheRightTime: 'Para que compre en el momento adecuado',
  usesCleverTechnologies:
    'Karma utiliza tecnología inteligente para controlar si el producto baja de precio o está nuevamente en stock, para que compre en el momento adecuado. ',
  notOnlySave:
    'Karma no solo le ahorra dinero, ¡también le genera dinero! Sin puntos. Sin trucos. Gana efectivo real cada vez que compra.',
  makeImpact: '¡Genere un impacto!',
  donate:
    'Compre como lo haría normalmente y vea cómo Karma sigue recompensando. Por cada compra de más de ${{over, number}}, donamos ${{dollars, number}} a una causa que elija.',
  accessToOffers: 'Acceda a ofertas exclusivas',
  accessItems:
    'Acceda a sus artículos guardados en un solo lugar, descubra ofertas exclusivas y las tendencias de Karma en el mundo.',
  hereIsHowItWorks: 'Así es cómo funciona',
  learnMoreAboutGives: 'Más información sobre Karma Gives',
  alwaysKnowWhenPricesDrop: 'Conoce siempre cuando los precios bajan',
  getNotifiedWhenBackInStock: 'Recibe una notificación cuando vuelva a estar disponible',
  findsYouBestCoupons: 'Te busca los mejores cupones',
  getKarmaCashWhenYouShop: 'Obtén Karma Cash al comprar',
  easilySaveAllThings: 'Ahorra fácilmente en todas tus cosas favoritas en +{{value, number}} mil tiendas',
  karmaProvidesGreatTechnology:
    'Karma te ofrece una excelente tecnología y flexibilidad para comprar con inteligencia, ayudándote a evitar compras impulsivas innecesarias, a la vez que ahorras tiempo y dinero.',

  accountActivityAndImportantInformation: 'Actividad de la cuenta e información importante',
  addKarmaToYourDesktop: 'Agrega Karma a tu escritorio para que puedas comprar<br/>durante las horas de trabajo.',
  addNumberItemsToList: 'Agregar {{value}} artículos a la lista',
  addOneItemToList: 'Agregar 1 artículo a la lista',
  addStuffYouFindToThisListToKeepItOrganized: 'Agrega cosas que encuentres a esta lista para mantenerla organizada.',
  alrightBut: '¡Muy bien, pero te vamos a poner los dientes largos!',
  areYouSureYouWantToDisconnectFromPaypal: '¿Estás seguro de que deseas desconectarte de Paypal?',
  balance: 'Saldo',
  buyWhateverYouWant: 'Compra lo que quieras y mantén feliz a la señora del banco.',
  cashIsBack: 'El <1>dinero</1> viene de vuelta',
  cashIsBackColoredRight: 'El dinero viene <1>de vuelta</1>',
  cashOutRewards: 'Cobrar recompensas',
  checkItOffAsYouBuy: 'Táchalo a medida que compras',
  congratulationsOnCashout: '¡Felicidades! Ahora puedes retirar tus recompensas.',
  connectToPayPalToCashOut: 'Conecta una cuenta PayPal para cobrar tus recompensas',
  connectYourPaypalAccountToCashOutRewards: 'Conecta tu cuenta PayPal para cobrar tus recompensas.',
  downloadOurApp: 'Descarga nuestra aplicación y ten <1>Karma siempre contigo</1>',
  dropSomeShoppingInspo: 'Deja caer un poco de inspiración de compras en tu familia.',
  earnKarmaCashShort: 'Hasta {{percent, number}} % mil en efectivo',
  earned: 'Ganado',
  enterYourPassword: 'Ingresa tu contraseña',
  firstAddToList: 'Primero, agrega a la lista',
  followOnLinkedin: 'Síguenos en LinkedIn',
  freshOutCoupons: 'No hay cupones',
  getBuzzedWhenYourWishes: 'Vuélvete loco 🐝 cuando tus "deseos" bajen de precio o vuelvan a estar disponibles.',
  getIt: 'Consíguelo.',
  getTheApp: 'Obtener la aplicación',
  gotIt: 'Entendido',
  gotStuck: '¿Te quedaste atascado? Haz clic <1>aquí</1> para ponerte en marcha.',
  happyShoppers: '👉 Más de {{count, number}} millones de compradores satisfechos',
  howElseCanWeImproveKarma: '¿De qué otra manera podemos mejorar Karma?',
  iNeedThisInMyLife: 'Necesito esto en mi vida',
  info: 'Información',
  itemUpdatesUnsubscribe: 'Actualizaciones de artículos (bajadas de precios, reabastecimientos y más)',
  itsAGhostTown: 'Es un pueblo fantasma.',
  joinTheTeam: 'Únete al equipo',
  karmaGetsYouTheLowestPossiblePrice: 'Karma te da el precio más bajo posible 👏 cada 👏 vez.',
  karmaIsAnApp: 'Karma es una aplicación y extensión que te ayuda a ahorrar tiempo y dinero mientras compras en línea.',
  karmaYourFavoriteBrands: '<1>Karma</1> tus marcas favoritas',
  karmaYourFavoriteBrandsMobile: '<1>Karma</1> tus marcas favoritas',
  knowHowToShopBetter: 'Aprende a <1>comprar mejor</1> que los demás',
  letsPlay: 'Juguemos',
  lolWereAVerbNow: '(lol, ahora somos un verbo)',
  lookAtYouLittleScroller: 'Mírate, qué bien usas el ratón. Felicitaciones por llegar tan lejos.',
  lookAtYouLittleScrollerMobile: 'Mírate, qué bien usas el ratón.',
  lookingForInfluencer: '¿Buscas asociaciones publicitarias?',
  markItemsYouveMadeYourOwnItFulfillsSoGood: 'Marca los artículos que hayas creado tú mismo. ¡Se cumple tan bien!',
  markNumberItemsAsFullfilled: 'Estás a punto de marcar {{value}} artículos como completados',
  meetYouAtIn: 'Nos vemos en {{retailer}} en 3 segundos...',
  missOut: 'te pierdas nada',
  missingYouAlready: '¡Ya te extrañé!',
  money: 'dinero',
  never: 'Nunca',
  nevermind: 'No importa.',
  noFomoHere:
    'No hay nada interesante aquí. Te avisaremos cuando tus "deseos" bajen de precio o vuelvan a estar disponibles.',
  noNotificationsYet: 'Ya está todo listo, por ahora',
  noNotificationsYet2:
    'Tan pronto como haya una bajada de precio o una actualización de reposición, la encontrarás aquí.',
  nowLetsTalPrivacy: 'Ahora hablemos de <1>privacidad<1/>',
  nowLetsTalkAboutPrivacy: 'Ahora hablemos de privacidad 🤫',
  onlineShoppingButBetter: 'Compras en línea, pero <1>mejor</1>',
  orCopyTheLinkBelowAndSend: 'O copia el siguiente enlace y envíalo al estilo de la vieja escuela.',
  other: 'Otros',
  passwordValidationNote:
    'Debe tener al menos 8 caracteres, incluyendo una letra mayúscula, una letra minúscula y un número.',
  payLess: 'Pagar menos',
  playFavesWithYourItems: 'Añade tus artículos a favoritos',
  preparingYourKarmaCash: 'Preparando tu Karma Cash.',
  savingMoneyIsHotLetsGetOnThat: 'Ahorrar dinero es bueno. Empecemos con eso ✌️',
  search: 'Buscar',
  seeHowToSave: 'Descubre cómo guardar',
  skipForNow: 'Omitir por ahora',
  soFarWeSavedYou: 'Hasta ahora, te hemos ahorrado',
  sorryForInconvenience: 'Lamentamos las molestias. Prometemos que valdrá la pena.',
  starReviews: '👉 Más de {{count, number}} mil reseñas de  4,7 estrellas',
  starTheThingsYouNeedInYourLifeForQuickAccess:
    'Marca con una estrella las cosas que necesitas en tu vida para un acceso rápido.',
  stillWannaUnsubscribe: '¿Todavía quieres <1>cancelar la suscripción a todos los correos electrónicos de Karma</1>?',
  storePromotionsAndOtherMarketingEmails: 'Promociones de tienda y otros correos electrónicos de marketing',
  storesWorldwide: '👉 Más de {{count, number}} mil tiendas en todo el mundo',
  stuffWorthSavingGoesHere: 'Cosas que vale la pena guardar aquí 👇',
  sureYouWantToDeleteTheseNumberItems: '¿Estás seguro de que deseas eliminar estos {{value}} artículos?',
  sureYouWantToDeleteThisList: '¿Estás seguro de que desea eliminar esta lista?',
  takeControlOfYourInbox: 'Toma el control de tu bandeja de entrada',
  theMotherOfAllWishLists: 'La madre de todas las <1>listas de deseos<1/>',
  thisFieldIsRequired: 'Este campo es obligatorio',
  toCashOutRewards: 'Para cobrar tus recompensas, debes alcanzar un mínimo de ${{minimum, number}}.',
  toCashOutYouMustReach: 'Para cobrar tus recompensas, debes alcanzar un mínimo de ${{currency}}{{value, number}}.',
  tryAgain: 'Inténtalo de nuevo',
  trySearchingForSomethingElse: 'Intenta buscar otra cosa.',
  updatePreferences: 'Actualizar preferencias',
  wantIt: 'Lo quiero',
  weAreBusySelfImproving: 'Estamos ocupados automejorando',
  weAreVerySeriousAboutPrivacy:
    'Nos tomamos muy en serio la privacidad y nunca venderemos tus datos a terceros. ¡Nunca!',
  weDontSellYourData: 'No vendemos tus datos',
  weGotStuckOnOurWay: '¡Nos quedamos atascados en nuestro camino a {{retailer}}!',
  weHitYouUp: 'Te avisaremos cuando encontremos un código promocional para esta tienda.',
  weHustleForCoupons: 'Nos obsesionan los cupones',
  weUseBankLevelSecurity: 'Utilizamos seguridad a nivel bancario',
  wellApplyCodesThatSaveYouMoney: 'Aplicaremos códigos que te ahorrarán $$$.',
  wellTrackAndScanForCoupons:
    'Rastrearemos y buscaremos cupones para que no tengas que hacerlo. <br/> (Está bien, no lo haremos nosotros, pero sçi nuestra IA)',
  wellTrackAndScanForCouponsMobile: 'Haremos un seguimiento y buscaremos cupones para que no tengas que hacerlo.',
  wereAVerbNowLol: '(somos un verbo ahora lol)',
  wereGoingShopping: 'Vamos de compras',
  wereHereToMakeItYours: 'Estamos aquí para <1>#make_it_yours</1>',
  whyUnsubscribeFromAllOurMoneySavingEmails:
    '¿Por qué cancelar la suscripción a todos nuestros correos electrónicos que ahorran dinero cuando puedes desactivar solo aquellos que no son relevantes?',
  withKarmaYouCanBuy: 'Con Karma puedes comprar<br />lo que quieras y mantener feliz a<br />la señora del banco.',
  withKarmaYourCash: 'Con Karma, tu dinero vuelve a ti a lo vudú, como una especie de dinero mágico.',
  yesDisconnect: 'Sí, desconectar',
  yesIDoThat: 'Sí, lo hago.',
  youHaveToReconnectToWithdraw: 'Tendrás que volver a conectarte para cobrar tu Karma Cash.',
  youNeedToSaveItemsBeforeYouCanShareYourList: 'Es necesario guardar los artículos antes de poder compartir tu lista.',
  youReATen: '¿Eres un 10 y pagas con Karma? Compra en línea y consigue el precio más bajo posible 👏 cada 👏 vez.',
  youReAboutToMuteNumberItems: 'Estás a punto de silenciar las notificaciones de {{value}} artículos',
  yourCashWillComeBackToYou: 'Tu dinero volverá a ti como una especie de vudú, como dinero mágico. ',
  yourInformationIsSecured:
    'Tu información está protegida con el último cifrado de 256 bits para una protección completa.',
  yourSearchCameUpEmpty: 'Tu búsqueda no ha dado resultados',
  total: 'Total',
  keepAnEyeOutForTheStatusUpdatesWeWillSendToEmail: 'Sigue atento a las actualizaciones que enviaremos a {{email}}.',
  psaDidYouKnow: 'PSA: ¿Sabías que puedes',
  addWishItemsFromAnyBrand:
    'añadir artículos favoritos de cualquier marca y esperar que te avisemos cuándo debes comprarlos?',
  capturePurchaseReadyShoppers: 'Atrae a compradores potenciales mediante cupones promocionales.',
  gainFullFunnelInsights: 'Cada artículo guardado aporta datos sobre la intención de compra.',
  boostCartEngagement: 'Mejora el servicio de compra con nuestras actualizaciones instantáneas de artículos.',
  promoteLongTermGrowth: 'Crece a largo plazo mediante campañas personalizadas en Karma.',
  reachNewAudiences: 'Llega a más audiencia con campañas adaptadas a tu marca',
  influencerCollaborations: 'Colaboraciones con influyentes',
  teamUpWithTopInfluences: 'Colabora con los influyentes que mejor encajen con tu marca.',
  socialPosts: 'Publicaciones sociales',
  giveANewFollowing: 'Gana nuevos seguidores con campañas personalizadas y llenas de contenido.',
  brandFocusedNewsletters: 'Boletín centrado en la marca',
  letYourOffersShine: 'Destaca tus ofertas en los correos más populares.',
  driveTrafficWhenYouNeedIt: 'Atrae tráfico directamente con nuestras páginas de inicio para compras',
  dashboardBanners: 'Banner de control',
  flauntYourLatestOfferings: 'Presuma lo último en ofertas con imágenes llamativas.',
  dailyFeatured: 'Destacado diario',
  highlightDeals: 'Resalta las ofertas más relevantes.',
  dedicatedPopUps: 'Pop-ups dedicados',
  activelyServeYourPromos: 'Ofrece tus promociones dinámicamente para llamar la atención.',
  createAnotherTouchpointForYourBrand: 'Crea otros touchpoints para tu marca',
  increaseEngagement: 'Aumenta la fidelización y conversión mediante notificaciones automáticas en móviles y web.',
  karmaIsOnlineShoppingButBetter: 'Karma es comprar en línea, pero mejor.',
  transactionsNumber: '👉 {{count, number}}M+ transacciones',
  partnershipsReview4: '¡Es ideal para la lista de deseos y ahorrar dinero!',
  partnershipsReviewAuthor4: 'Emily Smith',
  partnershipsReview5:
    'Literalmente es la mejor extensión que he descargado. ¡Es ideal para ahorrar y ganar dinero al mismo tiempo!',
  partnershipsReviewAuthor5: 'Selena Roblox',
  noNeedToSlideIntoOurDMs:
    'No tendrás que recurrir a nuestro chat (aunque podrías 😏). Nuestro equipo estará disponible para ti.',
  supportIssues: 'Soporte técnico',
  adsAndPartnerships: 'Anuncios y socios',
  jobOpportunities: 'Ofertas de empleo',
  publicRelations: 'Relaciones Públicas',
  letsChat: 'Charlemos',
  earnings: 'Ganancias',
  allTime: 'Siempre',
  ordersMayTakeHoursToAppear: 'Los pedidos pueden tardar hasta {{value, number}} horas en aparecer.',
  youHaventReceivedAnyPayoutsYet: 'Aún no has recibido ningún pago ',
  alerts: 'Alertas',
  featuresReview1:
    'Añadí unos zapatos a favoritos y al día siguiente me avisaron de un descuento del 50 %. ¿Cómo es que no compré antes con ustedes?',
  featuresReviewAuthor1: 'Sandra C. Martinez, revisora de la App Store',
  featuresReview2:
    'Esta es la mejor app. de compras inteligentes que conozco. ¡Siento que defraudo al sistema porque ahorro dinero de verdad! Solía pagar siempre completo así que me siento fatal.',
  featuresReviewAuthor2: 'Gertie D. Ackerman, revisora de la App Store',
  featuresReview3:
    'Compré un portátil nuevo que ya tenía un 25 % de descuento. Cuando activé "pagar con Karma", noté que el total era aún menor. Esto ocurre cada vez que pago con la app.',
  featuresReviewAuthor3: 'Irene J. Cox, revisora de la App store',
  downloadNow: 'Descargar ahora',
  wannaBeDeskBuddies: '¿Quieres ser nuestro aliado?',
  seeOpenPositions: 'Ver vacantes',
  weLiveAndSwearBy: 'Vivimos y apostamos por...',
  goodKarma: 'el buen Karma',
  weBelieveThatGoodVibes: 'Creemos que vivir con ~buen ánimo~ es un estilo de vida y lo mantenemos.',
  beingTrue: 'Ser fieles',
  theresNoUSvsU: 'No es un nosotros contra ti. Nuestras mesas son redondas y te guardamos un sitio.',
  passion: 'Pasión',
  weHaveThatFireYouKnow: 'Tenemos ese fuego, ¿sabes? Trabajamos con empuje, persistencia y amor por lo que hacemos.',
  entrepreneurship: 'Emprendimiento',
  weWantItWeGetIt:
    'Lo queremos, lo conseguimos. No perdemos el tiempo en reuniones interminables por Zoom. Perseguimos lo que queremos.',
  mutuality: 'Mutualidad',
  wereMadeUpOfAwesomePeople:
    'Contamos con un equipo increíble que entiende que no basta con trabajar en equipo. Realmente nos preocupamos por los demás. ',
  buildCustomerLoyalty: 'Fideliza a tus clientes con reembolsos instantáneos',
  itemSaving: 'Ofertas',
  itemUpdates: 'Actualización de artículos',
  backInStockAlerts: 'Nuevo stock',
  lowInStockAlerts: 'Queda poco',
  storePromotions: 'Ofertas de tienda',
  exclusive: 'exclusivas',
  couponCopied: 'Cupón copiado',
  completedDesc:
    'La tienda ha confirmado tu pedido. Los Karma Cash pueden retirarse {{days, number}} días después de la compra y solo cuando tengas {{currency}}{{amount, number}} como mínimo.',
  notQualifiedDesc: 'Pueden aplicarse restricciones en los artículos que compraste.',
  pendingDesc:
    'La tienda ha recibido tu pedido. Puede figurar como pendiente hasta {{days, number}} días, según la política de reembolso de la tienda.',
  cancelledDesc: 'La tienda nos informó que tu pedido se canceló o se reembolsó completamente.',
  downloadNowItsFree: 'Descárgalo ahora, es gratis',
  weLiveToMakeOurShoppersHappy: 'Vivimos para complacer a nuestros compradores',
  moneySavedForYou: 'Dinero ahorrado para ti',
  happyShoppers2: 'Compradores felices',
  starReviews2: '{{value, number}} opiniones',
  storesWorldwide2: 'Tiendas globales',
  whyYouWill: '¿Por qué te',
  it: 'gustará?',
  multiBrandWishLists:
    '¿Favoritos de varias marcas?  Tranquilízate. Encuentra lo que te interesa de cualquier marca, añádelo a tu lista Karma y espera que te digamos cuándo comprarlo.',
  addKarmaToYourMobile: 'Descarga<br /> nuestra app.<br /> y llévate<br /> a Karma contigo',
  appStoreReview1:
    'Añadí unas sombras a favoritos y al día siguiente me avisaron de que tenían un 50 % de descuento. ¡Gracias!',
  appStoreReview2: 'Que el precio más bajo se encuentre en mi bolsillo es muy tentador. Pero me encanta.',
  appStoreReview3: 'Conseguí una oferta de auriculares mientras esperaba el autobús. Es demasiado fácil.',
  appStoreReviewBy: 'Opinión de App store por: {{name}}',
  yourInfoSafeWithUs: 'Tu información está segura con nosotros',
  whyGiveOutYourCCDeets: '¿Por qué revelar tus datos cuando puedes guardarlos en un solo sitio?',
  partyForOne: 'Fiesta para 1',
  wellNeverSellYourDataToAnyThirdParties:
    'No venderemos nunca tus datos a terceros, punto. Gracias a la seguridad del sistema, tus datos estarán protegidos.',
  buyWithoutATrace: 'Compra sin rastro',
  weWontLetAnyoneTrackYourShoppingHabits: 'No permitiremos registros de tus hábitos de compra. Aquí nadie juzga.',
  spotSomethingYouLoveFromAnyBrand:
    'Encuentra lo que te interesa de cualquier marca, añádelo a tu lista Karma y espera que te digamos cuándo comprarlo.',
  needIt: 'Lo necesito.',
  appStoreReviewer: 'Revisor de App Store',
  appStoreReview4:
    'Recurro a esta app. para controlar todos mis favoritos en un solo lugar y comprarlos al mejor precio posible.',
  appStoreReview5: 'Esta aplicación me ha ayudado a ahorrar mucho dinero.',
  wishlist: 'Favoritos',
  getTheLowestPriceAtAllYourFavoriteBrands: 'Disfruta de precios bajos en tus marcas favoritas.',
  getTheAppForFree: 'Consigue la app. gratis',
  addKarmaSpaced: 'Añade Karma<br /> al escritorio<br /> para realizar compras<br /> en horas laborales.',
  spotSomethingYouLoveFromAnywhere:
    'Encuentra lo que te interesa de cualquier lugar, añádelo a tu lista Karma y espera que te digamos cuándo comprarlo.',
  getCouponsCashbackWithPeriod: 'Obtén cupones, reembolsos, alertas de rebajas.<br />Paga siempre lo mínimo.',
  getCouponsCashbackWithAnd: 'Obtén cupones, reembolsos, alertas de rebajas <br /> y paga siempre el precio más bajo.',
  clickTheLoginLinkFromYourPhone: 'Haz clic desde tu móvil para acceder a la aplicación Karma.',
  getItOn: 'Disponible en',
  downloadOnThe: 'Descárgala en la',
  likeShoppingFromYourComputer: '¿Prefieres comprar desde tu computadora?',
  getTheKarmaExtensionToShopEvenBetter: 'Instala la extensión Karma para mejorar tus compras.',
  headerMobileLogin: 'Karma | Instalar',
  noFomoHereMobile: 'No FOMO aquí. Recibirás avisos cuando tus favoritos rebajen o vuelvan a estar disponibles.',
  withKarmaYourCashMobile: 'Con Karma, tu dinero regresa como un vudú mágico.',
  spotSomethigYouLoveMobile:
    'Encuentra lo que te interesa de cualquier lugar, añádelo a tu lista Karma y espera que te digamos cuándo comprarlo.',
  wellTrackAndScanForCouponsMobile2:
    'Seguiremos y escanearemos cupones para que no tengas que preocuparte (bueno, nuestra IA lo hará)',
  weHustle: 'Nos apresuramos',
  forCoupons: 'por <1>cupones</1>',
  theMotherOfAll: 'La madre de todos',
  wishlists2: 'los favoritos',

  // NEW PHRASES
  moneySavedForUsers: 'Money saved for users',
  whatItsLikeWorkingAtKarma2: 'What’s it like working at Karma?',
  makeItYours: 'Make it yours',
  continueWith: 'Continue with {{provider}}',
  onItsWay: 'On its way...',
  weVeEmailedInstructionsForResettingYourPassword:
    'We’ve emailed instructions for resetting your password to {{email}}',
  wrongEmailTryAgain: 'Wrong email? Try again',
  goToEmail: 'Go to email',
  heyYouLetsJumpBackIntoSaving: 'Hey you! Let’s jump back into saving',
  firstConfirmItsYou: 'First, confirm it’s you',
  letsDoThis: 'Let’s do this',
  signUpAndGet: 'Sign up and get',
  moneyOnUs: '{{currency}}{{value, number}} on us',
  usersHeroTitle: 'Your\nbank account’s gonna love this.',
  tellMeMore: 'Tell me more',
  createNewPassword: 'Create new password',
  joinKarma: 'Join Karma',
  whyYouLoveMerchantsTitleDesktop3: '<1>One-swipe<1/> checkout is yours',
  whyYouLoveMerchantsSubtitle3: 'Pay with Karma works with all retail platforms, with zero\nintegration and no fees.',
  saveItemsFromStores: 'Save items from your favorite stores with a single click',
  easilySaveAndKeep:
    'Easily save and keep track of everything you want to buy. The best\npart? You’ll get notified the second any of your saved items\ngo on sale, or come back in stock.',
  addTheFreeKarma:
    "Add the free Karma button to your browser so you can\neasily keep track of everything you want to buy. The best\npart? You'll get notified the second any of your saved items\ngo on sale, drop in price, or come back in stock.",
  getButtonAndGetEverything: 'Get the free Karma button and\nget everything you want on sale',
  getKarmaItIsFree: "Get Karma - It's Free!",
  onYourPhone: 'On your phone? Save items on the go with our free app',
  hereHowTheButtonWorks: "Here's how the Karma\nbutton works",
  dontSellMyPersonalInformation: 'Don’t sell my personal information',
  postCode: 'Postcode',
  ifThisWontHelp: "If this won't help, ",
  contactSupport: 'contact our support center.',
  wellKeepAnEyeOnIt: 'We’ll keep an eye on it & will let u know ASAP if the price drops',
  seeYourSavedItems: 'See your saved items',
  logInAndSaveItemToKarma: 'Login & Save item to Karma',
  blogPromotions: 'Blog promotions',
  expiringIn: 'Expiring in',
  goToStore: 'Go to store',
  visitStorePage: 'Visit store page',
  favStoresUpdated: 'Your favorite stores were successfully updated.',
  availableCoupons_plural: '{{count}} available coupons',
  availableCoupons: '{{count}} available coupon',
  findAndComparedTheBestDeals: 'Finds and compares the best deals',
  karmaHelpsYouShopSmarter: 'Karma helps you shop smarter by instantly comparing products from different retailers.',
  expiring: 'Expiring',
  today: 'Today',
  tomorrow: 'Tomorrow',
  days: '{{count, number}} DAY',
  weeks: '{{count, number}} WEEK',
  months: '{{count, number}} MONTH',
  daysPlural: '{{count, number}} DAYS',
  weeksPlural: '{{count, number}} WEEKS',
  monthsPlural: '{{count, number}} MONTHS',
  tellUsWhyYouUninstalledTheExtension: 'Tell us why you uninstalled the extension',
  tooManyPopUpsWhileIShop: 'Too many pop-ups while I shop',
  couponsDontWork: 'Coupons don’t work',
  dataPrivacyConcerns: 'Data privacy concerns',
  causedProblemsWithMyBrowser: 'Caused problems with my browser',
  tellUsMoreOptional: 'Tell us more (optional):',
  shareYourThoughtsWithUs: 'Share your thoughts with us',
  myFavoriteStoresAreNotSupported: 'My favorite stores are not supported',
  notReadyToLetGoYet: 'Not ready to let go yet? Reinstall Karma with just one click',
  chatWithUsAndGet: 'Chat with us & get<br />${{count}} Amazon Gift Card',
  scheduleAMeetingAndEarnGiftCard:
    'Schedule a {{minutes}}-minute interview and earn a<br />${{count}} Amazon gift card.',
  scheduleAnInterview: 'Schedule an interview',
  compareProductsBeforeYouBuy: 'Compare products before you buy',
  downloadWithKarmaAndSaveMajorCash: 'Download Karma<br/>and save major $$$',
  youSearchedFor: 'You searched for',
  yourSavedItems: 'Your saved items',
  moreForYou: 'More for you',
  headerGlobalSearch: 'Karma | {{query}}',
  noResultsFoundFor: 'No results found for',
  pleaseReviewYourSpellingOrTryDifferentKeyword: 'Please review your spelling or try different keyword',
  heyThere: 'Hey, {{name}}',
  myAccount: 'My account',
  accountSettings: 'Account settings',
  trendingNow: 'Trending now',
  helpUsImproveKarma: 'Help us improve Karma',
  yourFavoriteStores: 'Your favorite stores',
  chooseYourFavoriteStores: 'Choose your favorite stores',
  viewStoreInfo: 'View store info',
  addStore: 'Add store',
  findMoreStores: 'Find more stores',
  whatAreYouShoppingFor: 'What are you shopping for?',
  searchForAnyItemBrandOrStore: 'Search for any item, brand or store',
  savedFromStore: 'Saved from store',
  manageFavoriteStores: 'Manage favorite stores',
  hasNoActiveCoupons: '{{retailer}} has no active coupons',
  checkOutOtherStores: 'Check out other stores for more deals',
  youCanStillEarn: 'You can still earn {{number}}% cashback with Karma!',
  retailerCoupons: '{{retailer}} coupons',
  nothingSavedYet: 'Nothing saved yet?',
  useOurBrowserExtension:
    'Use our browser extension on your {{retailerName}} visit to quickly add items to your favorites. Your shopping journey starts here!',
  letsMakeItPersonal: 'Let’s make it personal',
  selectAndTrack: 'Select your favorite stores and track coupons and deals',
  chooseYourFaves: 'Choose your faves',
  code: 'Code',
  itemFound: '{{count, number}} item found',
  itemFound_plural: '{{count, number}} items found',
  storeFound: '{{count, number}} store found',
  storeFound_plural: '{{count, number}} stores found',
  itemsTitle: 'Items',
  storeInfo: 'Store info',
  preOwned: 'Pre-owned',
  refurbished: 'Refurbished',
  favorited: 'Favorited',
  allPromotionsOrOffers:
    "All promotions or offers by any of our partners are subject to the applicable partner's terms and conditions, which may be changed by the partner at its discretion. We may receive a commission for purchases made through our links or using our coupons. Eligibility for a welcome bonus is subject to section 5 of Karma’s Terms & Conditions.",
  shippingInfo: 'Shipping info.',
  freeShipping: 'Free shipping',
  overMinAmount: 'over {{currency}}{{value, number(minimumFractionDigits: 2)}}',
  delivers: 'Delivers',
  deliveryDays: '{{value, number}} days',
  freeReturns: 'Free returns',
  upToDays: 'up to {{value, number}} days',
  deliveryInfo: 'Delivery info.',
  returnsInfo: 'Returns info.',
  notAvailable: 'Not available',
  minOrderMayApply: 'Min. order may apply',
  hottestDeals: 'Hottest deals',
  discoverCouponsAndExclusiveOffers: 'Discover coupons and exclusive offers',
  moreFromStore: 'More from {{store}}',
  seeWhatsTrendingNow: 'See what’s trending now',
  automaticallyApplyCoupons:
    'Automatically apply coupons at checkout, save what you love and earn cashback — all with the Karma extension.',
  deleteMyAccount: 'Delete my account',
  areYouSureDeleteAccount: 'Are you sure you want to delete your account?',
  youHaveKarmaCash: 'You have {{currency}}{{value, number}} Karma Cash in your wallet.',
  deletingYourAccountWill:
    'Deleting your account will permanently remove all of your information, your saved items and your earnings from our database, and this action cannot be undone.',
  cancel: 'Cancel',
  accountDeletedSuccessfully: 'Account deleted successfully',
  shopOnTheGo: 'Shop on-the-go with Karma app',
  moveFromDevice: 'With Karma, effortlessly move from one device to another as you shop.',
  fewMomentsWeAreStillWorkingOnSavingItemsToThisList:
    'Few moments, we’re still working on saving items to this list... ',
  notificationDate: '{{date, shortFromNowDate}} ago',
  onboardingIOSTitle: 'Enable Karma on Safari \n to access our coupon scanner',
  enableKarmaOnSafari: 'Enable Karma on Safari to access our coupon scanner',
  alwaysAllowOnSites: 'Always allow on all sites to get access to coupons',
  iosOnboardingFlowSubtitle: '{{step, number}}/{{numberOfSteps, number}} {{text}}',
  extension: 'extension',
  howToEnable: 'How to enable',
  howToGetCoupons: 'How to get coupons',
  sweOnboardingStep1_1: 'Tap the <1>A</1><2>A</2> in the URL bar',
  sweOnboardingStepAlt1_1: 'Tap the aA in the URL bar',
  sweOnboardingStep1_2: 'Select “Manage Extensions”',
  sweOnboardingStep1_3: 'Toggle on',
  sweOnboardingStep2_1: 'Tap the <1>A</1><2>A</2> in the URL bar again',
  sweOnboardingStep2_2: 'Tap the',
  sweOnboardingStep2_3: 'Select “Always allow”',
  sweOnboardingStep2_4: 'Select “Always allow on every website”',

  sweOnboardingStep2_5_1: 'Go back to the',
  sweOnboardingStep2_5_2: 'Karma app',
  shopSmarter: 'Shop smarter with\nthe power of AI',
  joinOurUsers: 'Join our {{number, number}} million users\nwho are shopping\nsmarter from all their\ndevices',
  browseAndBuy:
    'Browse and buy from our desktop extension or\nuse the Karma app to ensure you’re always\ngetting the most out of your purchase.',
  joinCard1: '<0>{{value}}M+</0><1>shoppers</1>',
  joinCard2: '<0>15K</0><1>5 star reviews</1>',
  joinCard3: '<0>$5 cash</0><1>at sign-up</1>',
  joinCard4: '<0>2.3 million</0><1>mobile app users</1>',
  joinCard5: '<0>$7.50</0><1>avg order savings</1>',
  joinCard6: '<0>50 million</0><1>items tracked</1>',
  whatMakesKarmaGood: 'What makes Karma\nso good?',
  karmaGoodTitle1: 'Karma let’s you shop\nsmarter from anywhere',
  karmaGoodDescription1:
    'Karma’s browser extension meets you\nwherever you shop. Shop directly in the Karma\napp or share the items you want to Karma.',
  karmaGoodTitle2: 'Helps you plan your\nnext purchases',
  karmaGoodDescription2:
    'Not ready to buy just yet? Save it for later in the\nKarma app or share it to Karma and shop more\nmindfully. You can keep an eye on all your\nsaved items in one place.',
  karmaGoodTitle3: 'Finds and compares the\nbest deals',
  karmaGoodDescription3: 'Karma helps you shop smarter by instantly\ncomparing products from different retailers.',
  karmaGoodTitle4: 'So you can buy at the\nright time',
  karmaGoodDescription4:
    "Karma uses clever technology to track if the\nprice drops, or when it's back in stock, so you\ncan buy at the right time.",
  onlyOnMobile: 'Only on the mobile app',
  makeYourInspo: 'Make your inspo\na reality',
  easilyBringYourFavorite:
    'Easily bring your favorite social media looks\ninto your life, just share the posts to Karma and\nour AI will scan the image and find the items\nthat match.',
  empowerTouToMake: 'Empower you to\nmake the right\nchoices at the right\ntime when you shop\nonline.',
  dontTakeOurWord: 'Don’t take our word for it',
  totalSavings: 'Total savings',
  joinOurUsersDesktop: 'Join our {{number, number}} million users who are\nshopping smarter from all their devices',
  andAutomaticallyFindsYouCouponsAtCheckout: 'And automatically finds you coupons at checkout',
  clickAlwaysAllowOnEveryWebsite: 'Click <2>“Always Allow on Every Website“</2> so we can work on all your fave sites',
  weNeedYourPermissionToAutomaticallyShowYouTheBestDeals:
    'We need your permission to automatically show you the best deals on all your favorite shopping websites.',
  yourPrivacyAndSecurityIsImportantToUs: 'Your privacy and security are important to us - it’s in our',
  whileKarmaWillStillWork: 'While Karma will still work, it’ll require more effort on your part to find deals.',
  unfortunatelyWithoutTheAlwaysAllowPermission:
    'Unfortunately, without the “Always Allow on Every Website” permission you’ll have to manually click Karma whenever you shop',
  noThanksSignUpForAnAccount: 'No thanks, Sign up for an account',
  logInWithEmail: 'Log in with email',
  signUpWithEmail: 'Sign up with email',
  iAgreeToTermsOfUseAndPrivacyPolicy: 'I agree to <1>Terms of Use</1> and <3>Privacy Policy</3>',
  joinOurEnergeticTeam: 'Join Our Energetic Team',
  atKarmaWeReNotJustAboutWork:
    'At Karma, we’re not just about work; we’re about creating an environment where everyone’s input is valued and where each day brings a new opportunity for growth and impact.',
  beAPartOfTheJourney: 'Be a part of the journey',
  noPositionsAvailable: 'No positions available',
  apply: 'Apply',
  dontSeeAnythingThatFitsYourSkillSet:
    "Don't see anything that fits your skill set? Don't let that stop you from reaching out! If you think you can help us grow, send an email to",
  ifYouReReadyToMakeAnImpact:
    'If you’re ready to make an impact and thrive in a setting that values speed, fun, and collaboration, Karma might be your next great adventure.',
  exploreOurCareerOpportunities:
    'Explore our career opportunities and find out how you can contribute to our exciting journey ahead. Join us, and let’s achieve greatness together!',
  welcomeToKarma:
    'Welcome to Karma—where technology meets savvy shopping. Join us in our mission to revolutionize the way you shop, ensuring every purchase is just right for you.',
  empoweringSmartShopping: 'Empowering Smart Shopping:\nThe Karma Story',
  ourMissionPart1:
    'At Karma, we believe in empowering shoppers to make wise and informed purchasing decisions. Founded in 2015, our mission has always been to guide consumers toward smarter, more responsible shopping. As both a browser extension and mobile app, Karma harnesses the power of advanced AI to enhance your shopping experience. This technology predicts the optimal time to make a purchase, ensuring you get the best deal possible.',
  ourMissionPart2:
    'Over the years, Karma has continuously evolved, integrating cutting-edge technology to keep pace with the dynamic e-commerce landscape. We take pride in helping our users avoid buyer’s remorse and overspending by providing them with the tools they need to shop confidently!',
  hearFromOurUsers: 'Hear from our users',
  karmaPatents: 'Karma patents',
  systemAndMethodForPreauthorizing: 'System and method for preauthorizing database transaction commits',
  systemAndMethodForScraping: 'System and method for near real time web scraping',
  couponsHandled: 'Coupons? Handled',
  skipCouponHunt: 'Skip the coupon hunt. We’ll automatically apply the best discounts for you at checkout.',
  smartAlerts: 'Smart alerts for your wishlist',
  saveItemsGetAlerted: 'Save items and get alerted when prices drop or items are back in stock.',
  cashbackMadeSimple: 'Cashback made simple',
  earnMoneyBack: 'Earn money back on your purchases, turning shopping into savings.',
  startYourShoppingJourney: 'Start your shopping journey',
  searchForItemBrandStore: 'Search for an item, brand or store',
  quillDisclosure: 'HP, Epson, & Samsung excluded',
  theKButtonIsInMyWay: 'The K button is in my way',
  itWasntHelpful: 'It wasn’t helpful',
  reinstallTheExtension: 'Reinstall the extension',
  tooManyCouponPopupsAtCheckout: 'Too many coupon pop-ups at checkout',
  didYouKnowYouCanHideTheKButtonAndDisablePopupsFromYourSettings:
    'Did you know you can <1>hide</1> the K button and <3>disable</3> pop-ups from your settings?',
  theHardPartIsOverNowLetsMakeTheMostOfYourShopping: "The hard part's over — now let’s make the most of your shopping!",
  goToYourFavoriteRetailerToSeeKarmaInAction: 'Go to your favorite retailer to see Karma in action.',
  howToSaveItemsWithKarma: 'How to Save Items with Karma',
  justGoToAnyProductPageOnYourFavoriteSiteAndHitThe:
    'Just go to any product page on your favorite site and hit the bookmark icon next to the "K".',
  peekAtYourWishlistAnytime: 'Peek at Your Wishlist Anytime',
  tapTheKAndHeadToTheMyKarmaTabToSeeYourPicks: 'Tap the "K" and head to the “My Karma” tab to see your picks.',
  watchOurCouponScannerInAction: 'Watch Our Coupon Scanner in Action',
  atCheckoutSeeOurCouponScannerTryOutEveryCodeWeveFoundForYou:
    'At checkout, see our coupon scanner try out every code we’ve found for you.',
  hereToHelpNotToHinder: 'Here to Help, Not to Hinder',
  wantACleanerLook: 'Want a cleaner look? Easily hide any feature by turning it off\nfrom your settings.',
  takeKarmaWithYou: 'Take Karma with You',
  downloadTheKarmaAppToBuildYourWishlistAndReceiveAlertsWhereverYouAre:
    'Download the Karma app to build your wishlist and receive alerts wherever you are. Never miss a beat!',
  justGoToAnyProductPageOnYourFavoriteSiteAndHitTheSaveButtonInTheKarmaExtension:
    'Just go to any product page on your favorite site and hit the save button in the Karma extension.',
  tapTheKAndHeadToTheSavedTabToSeeYourPicks: 'Tap the “K" and head to the “Saved” tab to see your picks.',
  cookies:
    'If you accept cookies, we’ll use them to improve and customize your experience and enable our partners to show you personalized ads when you visit other sites. <2>Manage cookies and learn more</2>',
  decline: 'Decline',
  confirmYourCookieSettings: 'Confirm your cookie settings',
  youControlHowWeUseCookies:
    'You get to control how we use cookies on each device and browser you use. These settings will apply to your current device when you use {{browser}}.',
  whatAreCookies: 'What are cookies?',
  cookiesAndTrackingTechnologies:
    'Cookies and tracking technologies are small text files stored on your device when you use a web browser. Some cookies are essential for you to use our site, while other cookies collect data about your browsing habits. We use this data to give you the best experience.',
  marketing: 'Marketing',
  weUseMarketingCookies: "We use marketing cookies to deliver ads we think you'll like.",
  marketingCookiesLet: 'For example, marketing cookies let us show you personalized ads based on your interests.',
  performance: 'Performance',
  weUsePerformanceCookies: 'We use performance cookies to understand how you interact with our site.',
  performanceCookiesHelp:
    'For example, performance cookies help us learn which parts are the most popular and which parts we could improve for you.',
  functional: 'Functional',
  weUseFunctionalCookies: 'We use functional cookies to customize your experience.',
  functionalCookiesLet:
    'For example, functional cookies let us remember your preferences like language, country or region, and text sizes.',
  essential: 'Essential',
  weUseEssentialCookies: 'We use essential cookies to make our site work for you.',
  essentialCookiesLet:
    'For example, essential cookies let you securely sign in and browse our site. These cookies help us keep your account safe and prevent fraud.',
  youCanReadMoreAboutCookies: 'You can read more about how we use cookies in our <2>Cookie Statement</2>.',
  confirmCookieSettings: 'Confirm Cookie Settings',
  noMinimumOrderValue: 'No minimum order value',
  fromToDays: '{{value, number}} to {{value2, number}} days',
  headerLogin: 'Karma | Login',
  headerOnboarding: 'Karma | Onboarding',
};
